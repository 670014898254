// jQuery inview plugin
!function(a){"function"==typeof define&&define.amd?define(["jquery"],a):"object"==typeof exports?module.exports=a(require("jquery")):a(jQuery)}(function(a){function i(){var b,c,d={height:f.innerHeight,width:f.innerWidth};return d.height||(b=e.compatMode,(b||!a.support.boxModel)&&(c="CSS1Compat"===b?g:e.body,d={height:c.clientHeight,width:c.clientWidth})),d}function j(){return{top:f.pageYOffset||g.scrollTop||e.body.scrollTop,left:f.pageXOffset||g.scrollLeft||e.body.scrollLeft}}function k(){if(b.length){var e=0,f=a.map(b,function(a){var b=a.data.selector,c=a.$element;return b?c.find(b):c});for(c=c||i(),d=d||j();e<b.length;e++)if(a.contains(g,f[e][0])){var h=a(f[e]),k={height:h[0].offsetHeight,width:h[0].offsetWidth},l=h.offset(),m=h.data("inview");if(!d||!c)return;l.top+k.height>d.top&&l.top<d.top+c.height&&l.left+k.width>d.left&&l.left<d.left+c.width?m||h.data("inview",!0).trigger("inview",[!0]):m&&h.data("inview",!1).trigger("inview",[!1])}}}var c,d,h,b=[],e=document,f=window,g=e.documentElement;a.event.special.inview={add:function(c){b.push({data:c,$element:a(this),element:this}),!h&&b.length&&(h=setInterval(k,250))},remove:function(a){for(var c=0;c<b.length;c++){var d=b[c];if(d.element===this&&d.data.guid===a.guid){b.splice(c,1);break}}b.length||(clearInterval(h),h=null)}},a(f).on("scroll resize scrollstop",function(){c=d=null}),!g.addEventListener&&g.attachEvent&&g.attachEvent("onfocusin",function(){d=null})});

// On herefish tracking code test requests auto consent cookies and thus load tracking script
/*
if ( window.location.search === '?testTrackingCode=true') {
    var expiresDate = new Date();
    expiresDate.setTime(expiresDate.getTime() + (24 * 60 * 60 * 1000));
    document.cookie = 'cookie_consent={"v":1,"essential":true,"analytics":true};path=/;sameSite=Lax;domain=headhunter-it.com';
}
 */

// custom functions
var FE = {
	location: window.location,

    Lightbox: {
      init: function () {
          $('.lightbox').magnificPopup({
              type:'image',
              gallery:{enabled:true}
          });
      }
    },

	MobileMenu: {
		init: function() {
            FE.MobileMenu.injectBurgerIcon();
            //FE.MobileMenu.indicateSubMenu();
			$('.burger-icon').click(function() {
                //$('.nav-mobile').slideToggle();
                if ( $(this).hasClass('active') ) {
                    $('.nav-main').animate({
                        right: '-280px'
                    }, 500);
                    $(this).removeClass('active');
                } else {
                    $('.nav-main').animate({
                        right: 0
                    }, 500);
                    $(this).addClass('active');
                }
            });
		},
        injectBurgerIcon: function () {
           $('.header-mid-inner').prepend('<button class="burger-icon"><div></div><div></div><div></div></button>');
        },
        indicateSubMenu: function() {
            $('.nav-mobile > ul > li').each(function() {
                if ( $(this).find('ul').length ) {
                    $(this).prepend('<span>&gt;</span>');
                }
                $(this).find('span').click(function() {
                    $(this).siblings('ul').slideToggle();
                });
            });
        }
	},

    HeaderShrink: {
        init: function () {
            window.addEventListener('scroll', function(e){
                var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                    shrinkOn = 50;
                if (distanceY > shrinkOn) {
                    $('.page > header').addClass('smaller');
                } else {
                    if ( $('.page > header').hasClass('smaller') ) {
                        $('.page > header').removeClass('smaller');
                    }
                }
            })
        }
    },

    CounterAnimation: {
        init: function () {
            $('section.stats').one('inview', function(event, isInView) {
                FE.CounterAnimation.start();
            });
        },
        start: function () {
            $('.stats-value-figure').each(function () {
                var counterStart = 0;
                if ( $(this).data('counterStart') ) {
                    counterStart = $(this).data('counterStart');
                }
                $(this).prop('Counter', parseInt(counterStart)).animate({
                    Counter: $(this).text()
                }, {
                    duration: 4000,
                    easing: 'swing',
                    step: function (now) {
                        $(this).text(Math.ceil(now));
                    }
                });
            });
        }
    },

    HeaderSlider: {
        init: function() {
            $('.ce-slider-inner').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: true,
                arrows: true,
                infinite: true,
                speed: 1000,
                autoplaySpeed: 5000,
                fade: false,
                slide: '.ce-slider-slide-item',
                cssEase: 'linear',
                autoplay: true,
                centerMode: false,
                focusOnSelect: false
            });
        }
    },

    ReferenceSlider: {
        init: function() {
            $('.reference-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: true,
                arrows: true,
                infinite: true,
                speed: 1000,
                autoplaySpeed: 5000,
                fade: false,
                slide: '.ce-reference',
                cssEase: 'linear',
                autoplay: true,
                centerMode: false,
                focusOnSelect: false
            });
        }
    },
    
    ProcessSteps: {
        init: function () {
            $('ul.process-steps').before('<div class="process-steps-bar"></div>');
            var counter = 1;
            $('ul.process-steps li').each(function () {
                $('.process-steps-bar').append('<div>' + counter + '.</div>');
                counter++;
            });
            $('.process-steps-bar div').click(function () {
                $('.process-steps-bar div.active').removeClass('active');
                $(this).addClass('active');
                $('ul.process-steps li').hide();
                $('ul.process-steps li').eq( $(this).index() ).show();
            });
            $('.process-steps-bar div').first().addClass('active');
        }
    },

    RandomJobs: {
	    init: function () {
	        var $jobs = $('.openjobs-latest li');
            $jobs.hide();
            var i = 0;
            for ( ; i < 5; i++ ) {
                var $hiddenJobs = $('.openjobs-latest li:hidden');
                // todo: need to improve as nth-child ignores hidden, so duplicates can happen
                //var selectedLi = $('.openjobs-latest li:hidden:nth-child(' + Math.floor( (Math.random() * $hiddenJobs.length) + parseInt(1) ) + ')');
                var selectedLi = $('.openjobs-latest li:hidden').eq( Math.floor( (Math.random() * $hiddenJobs.length) + parseInt(1) ) );
                $(selectedLi).show();
            }
        }
    },

    JobFilter: {
	    subfilterMap: {
            6: [3,4,5,6,7,8,9,10],
            7: [3,4,5,6,7,8,9,10],
            8: [1,2],
            18: [7,8,9,40,41]
	    },
        defaultFilterAreaTitle: '',

	    init: function () {
	        // temp preset
            //$('.filter-areas input').eq(0).prop('checked', true);
            FE.JobFilter.defaultFilterAreaTitle = $('.filter-area-title').text();
            if ( $('.filter-areas input:checked').length ) {
                FE.JobFilter.checkSelectedArea();
            }
            $('.filter-area-title').on('click', function () {
                $('.filter-options').slideToggle();
                $(this).toggleClass('active');
            });
            $('.filter-areas input').on('change', function () {
                // clear category options
                $('.filter-category input:checked').prop('checked', false);
                $('.job-search form').submit();
            });
            $('.filter-categories input').on('change', function () {
                if ( $(this).is(':checked') ) {
                    //console.log('add filter');
                    FE.JobFilter.addCategoryFilter( $(this).val() );
                } else {
                    //console.log('remove filter');
                    FE.JobFilter.removeCategoryFilter();
                }
            });
            $('.filter-areas input:checked').on('click', function () {
                $('.filter-category input:checked').prop('checked', false);
                $('.open-jobs-list li').show();
            });
            if ( $('.filter-category input:checked').length ) {
                FE.JobFilter.preSelectCategoryFilter();
            }
        },
        checkSelectedArea: function () {
            var areaId = parseInt( $('.filter-areas input:checked').val() );
            $('.filter-area-title')
                .text( $('.filter-areas input:checked + label').text() )
                .addClass('active');
            if ( areaId === 6 || areaId === 7 || areaId === 8 ) {
                FE.JobFilter.showRelevantCategoryFilters( areaId );
            }

            if ( areaId === 5 || areaId === 10 || areaId === 11 ) {
                $('.filter-areas').addClass('wide-area-title');
            }

            // set CSS class for sub filter elements < 5
            if ( areaId === 8 ) {
                $('.filter-categories').addClass('reduced-height');
            }
        },
        showRelevantCategoryFilters: function (areaId) {
            $('.filter-categories .filter-category').hide();
            //console.log(FE.JobFilter.subfilterMap[areaId]);
            FE.JobFilter.subfilterMap[areaId].forEach(function (id) {
                $('.category-' + id).show();
            });
        },
        getCategoriesToShow: function () {
            var categoriesToShow = [];
            $('.filter-category input:checked').each(function () {
                categoriesToShow.push($(this).val());
            });
            // remove 1st (toggle all) and 2nd due to extbase check additional hidden field
            //categoriesToShow.splice(0, 2);
            //console.log(categoriesToShow);
            return categoriesToShow;
        },
        addCategoryFilter: function (catId) {
	        // hide all when very first category gets checked
            if ( $('.filter-category input:checked').length === 1 ) {
                $('.open-jobs-list li').hide();
            }
            $('.open-jobs-list li.cat-' + catId).show();
        },
        removeCategoryFilter: function () {
	        // show all when last filter get unchecked
            if ( $('.filter-category input:checked').length < 1 ) {
                $('.open-jobs-list li').show();
                return;
            }
            var categoriesToShow = FE.JobFilter.getCategoriesToShow();
            //console.log(categoriesToShow);
            $('.open-jobs-list li').hide();
            categoriesToShow.forEach(function (catId) {
                //console.log('.open-jobs-list li.cat-' + catId);
                $('.open-jobs-list li.cat-' + catId).show();
            });
        },
        preSelectCategoryFilter: function () {
            $('.open-jobs-list li').show();
            FE.JobFilter.removeCategoryFilter();
        }

    },

    CareerChances: {
        init: function () {
            $('.career-chances-tabs button').on('click', function () {
                $('.career-chances-tabs button').removeClass('active');
                $(this).addClass('active');
                $('.career-chances-tabbed-content-item').hide();
                //console.log($(this).parent().index());
                $('.career-chances-tabbed-content-item').eq( $(this).parent().index() ).show();
            });
        }
    },

    JobApplication: {
	    init: function () {
            // set form header with job title
            if ( $('#powermail_field_stellereferenz').val() !== '' ) {
                $('.tx-powermail form h3').append(': ' + $('#powermail_field_stellereferenz').val());
            }
        }
    },

    Contact: {
	    addApplicationButton: function () {
            var btn = $('.contact .sidebar .btn-primary').clone();
            $('.powermail_fieldwrap_type_submit .powermail_field ').append(btn);
        }
    },

    Marketing: {
	    init: function () {

	        $('.ce-marketing-btn.open').on('click', function () {
                $(this).parent().parent().addClass('active');
                $(this).siblings('.ce-marketing-full-text').slideDown('slow');
            });

            $('.ce-marketing-btn.close').on('click', function () {
                $(this).parent().parent().removeClass('active');
                $(this).siblings('.ce-marketing-full-text').slideUp('slow');
            });
        }
    },

    DynamicFormElements: {
        init: function () {
            var dynamicElementSections = $('.form-field-dynamic-add-init');
            $(dynamicElementSections).each(function () {
                $(this).addClass('form-field-dynamic-add-on');
                $(this).find('.powermail_field').append('<button class="add-item">+</button>');

                var dynamicItems = $(this).nextAll('.form-field-dynamic-add-item').slice(0,2);    // we assume we have 2 dynamic fields set
                $(dynamicItems).first().addClass('form-field-dynamic-add-on');
                $(dynamicItems).first().find('.powermail_field').append('<button class="add-item">+</button>');
                $(dynamicItems).hide();

            });

            FE.DynamicFormElements.makeAdd();
        },
        makeAdd: function () {
            $('.form-field-dynamic-add-on .add-item')
                .addClass('active')
                .on('click', function(e) {
                    e.preventDefault();
                    $(this).parents('.form-field-dynamic-add-on').next().fadeIn();
                });
        }
    },

    Faq: {
	   init: function () {
           $('.ce-faq h3').on('click', function () {
               $(this).siblings('.faq-answer').slideToggle();
               $(this).parent().toggleClass('open');
           });
       }
    },

    Video: {
        userConsentAvailable: false,

        init: function () {
            document.querySelectorAll('.video-keep-data-privacy[data-video-type="vimeo"]').forEach(function (videoItem) {
                //videoItem.addEventListener('click', FE.Video.checkVideoLoading);
                videoItem.addEventListener('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    FE.Video.loadVideo(videoItem);
                });
                if (sessionStorage.getItem('videoConsent') === '1') {
                    FE.Video.userConsentAvailable = true;
                }
                //FE.Video.addUserConsent(videoItem.closest('.video-area'));
            });
        },
        loadVideo: function (videoItem) {
            const videoId = videoItem.dataset.videoId;
            const videoArea = videoItem.parentElement;

            console.log('try to load video');

            if ( !document.querySelector('.external-video-consent')) {
                FE.Video.addUserConsent(videoArea, videoId);
            }

            //console.log(videoArea);

            // check potential permission via consent manager or any other persistent data such as session storage or cookie
            if ( FE.Video.userConsentAvailable) {
                videoArea.innerHTML = FE.Video.getVideoEmbedCode(videoId);
            } else {
                videoItem.nextElementSibling.classList.add('show');
            }

        },
        checkVideoLoading: function (e) {
            e.preventDefault();
            //console.log(e.currentTarget);
            const videoArea = e.currentTarget;
            // check potential permission via consent manager
            videoArea.nextElementSibling.classList.add('show');
        },
        getVideoEmbedCode: function (vimeoId) {
            return '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/' + vimeoId + '?h=96a48e54c0&byline=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>';
        },
        addUserConsent: function (videoArea, videoId) {
            const videoConsentHtml = `An dieser Stelle laden wir ein externes Video von Vimeo.<br>
                                        Mir ist bewusst, dass dabei Daten mit den Vimeo Servern ausgetauscht werden.<br>
                                        Weitere Informationen zu den <a href="https://vimeo.com/privacy" target="_blank" rel="noopener">Vimeo Datenschutzbedingungen</a>.
                                        <div class="btn-group"><button class="btn-video-confirm">Geht klar</button><button class="btn-video-deny">möchte ich nicht</button>`;

            const videoConsent = document.createElement('div');
            videoConsent.classList.add('external-video-consent');
            videoConsent.innerHTML = `<div class="video-consent-inner">${videoConsentHtml}</div>`;
            videoArea.append(videoConsent);
            videoArea.querySelector('.btn-video-confirm').addEventListener('click', function (e) {
                FE.Video.userConsentAvailable = true;
                FE.Video.loadVideo(videoArea.querySelector('.video-keep-data-privacy'));
            });
            videoArea.querySelector('.btn-video-deny').addEventListener('click', function (e) {
                e.target.closest('.external-video-consent').classList.remove('show');
            });
        }
    }

};

// init custom functions
(function ($, window, document, undefined) {

	$(function () {
        FE.Lightbox.init();
		FE.MobileMenu.init();

        if ( $('.ce-slider-slide-item').length > 1) {
            FE.HeaderSlider.init();
        }

        FE.HeaderShrink.init();

        if ( $('.stats-value').length ) {
            FE.CounterAnimation.init();
        }

        if ( $('ul.process-steps').length ) {
            FE.ProcessSteps.init();
        }

        if ( $('.career-chances').length ) {
            FE.CareerChances.init();
        }

        if ( $('.openjobs-latest').length ) {
            FE.RandomJobs.init();
        }

        if ( $('.job-search').length ) {
            FE.JobFilter.init();
        }

        if ( $('.reference-slider .ce-reference').length > 1 ) {
            FE.ReferenceSlider.init();
        }

        if ( $('#powermail_field_stellereferenz').length ) {
            FE.JobApplication.init();
        }

        if ( $('main.contact').length ) {
            FE.Contact.addApplicationButton();
        }

        if ( $('.form-field-dynamic-add-init').length ) {
            FE.DynamicFormElements.init();
        }

        if ( $('.ce-faq').length ) {
            FE.Faq.init();
        }

        if ( document.querySelector('.video-keep-data-privacy') ) {
            FE.Video.init();
        }

        // if ( $('.ce-marketing').length ) {
        //     FE.Marketing.init();
        // }

	});

}(jQuery, window, document));
